<template>
  <div eagle-form-item="html">
    <v-html-editor
      v-if="!disabledHtml"
      ref="htmlEditor"
      :upload-on-paste="uploadOnPaste"
      v-model="data"
    ></v-html-editor>

    <html-content
      v-if="disabledHtml"
      :value="data"
    ></html-content>
  </div>
</template>

<script lang="babel" type="text/babel">
import formTextType from '@/components/form/formItemTypes/formTextType'
export default {
  mixins: [formTextType],
  methods: {
    appendPhoto(photo) {
      this.$refs.htmlEditor.insertPhotos([photo])
    },
  },
  computed: {
    uploadOnPaste() {
      return this.formDataItem.uploadOnPaste === true
    },
    disabledHtml() {
      if(this.readonly) return true
      if(this.disabled) return true
      return false
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
